<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3>QR Management</h3>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="fetchQR">
            <!-- url -->
            <v-text-field
                v-model="url"
                label="url"
                required
            ></v-text-field>
            <v-btn color="primary" type="submit">Submit</v-btn>
          </v-form>

          <v-card>
            <v-card-text>
              <v-textarea v-model="img"></v-textarea>
              <v-img :src="img"></v-img>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>
          <h3>V CARD Generator</h3>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="genVCard">
            <v-text-field
              label="Organization"
              name="organization"
              v-model="vcard.organization"></v-text-field>
            <v-text-field
              label="Work Email"
              name="workEmail"
              v-model="vcard.workEmail"></v-text-field>
            <v-text-field
              label="Mobile"
              name="mobile"
              v-model="vcard.mobile"></v-text-field>

            <h3>Address Data</h3>
            <v-text-field
                label="Street"
                name="street"
                v-model="vcard.street"></v-text-field>
            <v-text-field
                label="City"
                name="city"
                v-model="vcard.city"></v-text-field>
            <v-text-field
                label="Province"
                name="stateProvince"
                v-model="vcard.stateProvince"></v-text-field>
            <v-text-field
                label="Postal Code"
                name="postalCode"
                v-model="vcard.postalCode"></v-text-field>
            <v-btn type="submit" color="primary">Submit</v-btn>
          </v-form>

          <v-card>
            <v-card-text>
              <v-textarea v-model="vCardString"></v-textarea>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>
          <h3>V CARD Generator Pro</h3>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="genVCardPro">
            <v-text-field
              label="First Name"
              name="firstName"
              v-model="vcardpro.firstName"></v-text-field>
            <v-text-field
              label="Last Name"
              name="lastName"
              v-model="vcardpro.lastName"></v-text-field>
            <v-text-field
              label="Job Title"
              name="title"
              v-model="vcardpro.title"></v-text-field>
            <v-text-field
              label="Email"
              name="email"
              v-model="vcardpro.email"></v-text-field>
            <v-text-field
              label="Mobile"
              name="mobile"
              v-model="vcardpro.mobile"></v-text-field>

            <h3>Address Data</h3>
            <v-text-field
                label="Street"
                name="street"
                v-model="vcardpro.street"></v-text-field>
            <v-text-field
                label="City"
                name="city"
                v-model="vcardpro.city"></v-text-field>
            <v-text-field
                label="Province"
                name="stateProvince"
                v-model="vcardpro.stateProvince"></v-text-field>
            <v-text-field
                label="Postal Code"
                name="postalCode"
                v-model="vcardpro.postalCode"></v-text-field>
            <v-btn type="submit" color="primary">Submit</v-btn>
          </v-form>

          <v-card>
            <v-card-text>
              <v-textarea v-model="vCardProString"></v-textarea>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
  import { API } from '@/services/qr'
  import { serverAPI } from '@/services/server';

  const vCardJS = require('vcards-js');

  export default {
    name: "Admin",
    data: () => ({
      url: "",
      img: "",
      vcard: {
        organization: "",
        workEmail: "",
        mobile: "",
      },
      vcardpro: {
        firstName: "",
        lastName: "",
        title: "",
        email: "",
        mobile: "",
        street: "",
        city: "",
        stateProvince: "",
        postalCode: "",
      },
      vCardString: "",
      vCardProString: ""
    }),
    methods: {
      fetchQR() {
        API.get('v1/create-qr-code', {
          params: {
            data: this.url,
            size: '128x128'
          }
        }).then(response => {
          if (response) {
            var uInt8Array = new Uint8Array(response.data)
            var i = uInt8Array.length
            var biStr = new Array(i)
            while (i--)
            {
              biStr[i] = String.fromCharCode(uInt8Array[i])
            }
            var data = biStr.join('')
            var base64 = window.btoa(data)
            var img = `data:image/png;base64,${base64}`
            this.img = img
          }
        })
      },
      genVCard() {
        const vCard = new vCardJS();
        //set properties
        vCard.organization =this.vcard.organization;
        vCard.workEmail = this.vcard.workEmail;
        vCard.cellPhone = this.vcard.mobile;
        vCard.workAddress.street = this.vcard.street;
        vCard.workAddress.city = this.vcard.city;
        vCard.workAddress.stateProvince = this.vcard.stateProvince;
        vCard.workAddress.postalCode = this.vcard.postalCode;

        this.vCardString = vCard.getFormattedString()
      },
      genVCardPro() {
        const vCard = new vCardJS();
        console.log(this.vcardpro)
        //set properties
        vCard.firstName = this.vcardpro.firstName;
        vCard.lastName = this.vcardpro.lastName;
        vCard.title = this.vcardpro.title;
        vCard.email = this.vcardpro.email;
        vCard.mobile = this.vcardpro.mobile;
        vCard.workAddress.street = this.vcardpro.street;
        vCard.workAddress.city = this.vcardpro.city;
        vCard.workAddress.stateProvince = this.vcardpro.stateProvince;
        vCard.workAddress.postalCode = this.vcardpro.postalCode;

        this.vCardProString = vCard.getFormattedString()
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>