/* eslint-disable */
import axios from 'axios'

//// API
export const serverAPI = axios.create({
    baseURL: process.env.BASE_URL,
    responseType: 'text/vcard',
    ...headers()
})

// API INTERCEPTOR
serverAPI.interceptors.response.use(
    response => response,
    errorHandler
)

//// HELPER METHODS
function headers() {
    return {
        headers: {
            "content-type": "application/json"
        }
    }
}

function errorHandler(error) {
    const data = error.response.data;

    switch (error.response.status) {
        case 400:
            console.log('error :', error);
            // swalMixin.methods.$_swalError("Error: Bad Request", data.error_detail)
            break;
        case 401:
            console.log('error :', error);
            // store.commit("auth/Logout");
            // router.push('/login');
            // swalMixin.methods.$_swalError("Error: Unauthorized Access", 'Please login');
            break;
        case 500:
            console.log('error :', error);
            // swalMixin.methods.$_swalError("Error: Server Error", 'Please try again later');
            break;
    }

    return error;
}